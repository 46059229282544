module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048,"quality":80,"disableBgImageOnAlpha":true,"backgroundColor":"none","wrapperStyle":"margin: 48px auto;","showCaptions":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-external-links","options":{"target":"_blank","rel":"noopener"}},{"resolve":"gatsby-remark-images","options":{"maxWidth":2048,"quality":80,"disableBgImageOnAlpha":true,"backgroundColor":"none","wrapperStyle":"margin: 48px auto;","showCaptions":false}},"gatsby-remark-image-attributes",{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"static"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#8747D1","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PD7G5RL","includeInDevelopment":true,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GENAIZ","short_name":"GENAIZ","start_url":"/","background_color":"#ffffff","theme_color":"#042A6C","display":"browser","icon":"/opt/build/repo/src/img/genaiz-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"38d19cc0fac49137870f1fe8b163d130"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    }]
