/** @jsx jsx */
import { jsx } from 'theme-ui';

import PostThumbnail from '../components/PostThumbnail';
import DownloadButton from '../components/DownloadButton';
import Slideshow from '../components/Slideshow';
import Table from '../components/Table';

export default {
  div: (props) => <div {...props} />,
  PostThumbnail: (props) => <PostThumbnail {...props} sx={{ my: 11 }} />,
  DownloadButton,
  Slideshow,
  Table,
  // img: (props) => <img {...props} />,
};
