import { theme } from './theme';

export default {
  ...theme,
  styles: {
    root: theme.styles.root,
    h1: {
      fontSize: ['21px', null, '38px'],
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h2: {
      fontSize: ['21px', null, '28px'],
      fontWeight: 'body',
      lineHeight: 'heading',
      mt: 11,
      mb: 7,
    },
    h3: {
      fontSize: ['16px', null, '20px'],
      fontWeight: 'heading',
      lineHeight: 'heading',
      mt: 11,
      mb: 7,
    },
    h4: {
      fontSize: '18px',
      fontWeight: 'body',
      lineHeight: 'heading',
      mt: 4,
      mb: 2,
    },
    p: {
      fontSize: '16px',
      my: 5,
    },
    hr: {
      height: '2px',
      bg: 'muted',
      my: 11,
    },
    ul: {
      pl: 5,
      listStyleType: 'disc',
      ul: {
        pl: 7,
        li: {
          mt: 0,
        },
      },
    },
  },
};
