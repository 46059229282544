import { isObject, mapKeys, mapValues } from 'lodash';

export function mapValuesDeep(obj, callback) {
  return isObject(obj)
    ? mapValues(obj, (value) => mapValuesDeep(value, callback))
    : callback(obj);
}

export const mapKeysDeep = (obj, callback) =>
  mapValues(mapKeys(obj, callback), (value) =>
    isObject(value) ? mapKeysDeep(value, callback) : value
  );
