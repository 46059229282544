/** @jsx jsx */
import { jsx } from 'theme-ui';

const PostThumbnail = (props) => {
  console.log('pp', props);
  return (
    <div
      {...props}
      sx={{
        bg: 'white',
        p: 7,
      }}
    >
      Thumbnail
    </div>
  );
};

export default PostThumbnail;
