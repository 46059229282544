/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { useState } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';

const Slideshow = ({ title, slides, ...props }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  return (
    <div {...props} sx={{ my: 10 }}>
      <Styled.h3 sx={{ fontWeight: 'heading' }}>{title}</Styled.h3>
      <Flickity
        options={{
          initialIndex: currentIndex,
          adaptiveHeight: true,
          on: {
            select: setCurrentIndex,
          },
        }}
        static
        sx={{
          mt: 5,
          '.flickity-viewport': {
            transition: 'height 0.2s',
          },
          '.flickity-prev-next-button': {
            opacity: 0,
            transition: 'opacity 0.1s',
          },
          '.flickity-page-dots': {
            position: 'static',
            my: 5,
            '.dot': {
              bg: 'gray4',
              '&.is-selected': {
                bg: 'secondary',
              },
            },
          },
          '&:hover': {
            '.flickity-prev-next-button': {
              opacity: 1,
            },
          },
        }}
      >
        {slides.map((slide, i) => (
          <figure
            sx={{
              mr: 5,
              width: '100%',
            }}
          >
            <img
              key={i}
              src={slide.image}
              alt={slide.caption}
              sx={{ display: 'block' }}
            />
            <figcaption sx={{ mt: 4 }}>
              {slides[currentIndex].caption}
            </figcaption>
          </figure>
        ))}
      </Flickity>
    </div>
  );
};

export default Slideshow;
