/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import { resetButton, resetLink } from '../utils/styles';

function getButtonTag(props) {
  if (props.to) {
    return Link;
  }
  if (props.href) {
    return OutboundLink;
  }
  return 'button';
}

export const Button = ({ variant, inverted, outlined, slim, ...props }) => {
  const Tag = getButtonTag(props);
  return (
    <Tag
      {...props}
      sx={{
        ...(Tag === 'button' && resetButton),
        ...((Tag === OutboundLink || Tag === Link) && resetLink),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '12px',
        textAlign: 'center',
        textTransform: 'uppercase',
        py: slim ? 2 : 2,
        // px: [5, 7],
        px: 5,
        borderRadius: '4px',
        // minHeight: (t) => (slim ? t.space[8] : t.space[9]),
        '&:disabled': {
          opacity: '0.5',
          cursor: 'not-allowed',
        },
        variant: `buttons.${variant}${inverted ? '-inverted' : ''}${
          outlined ? '-outlined' : ''
        }`,
      }}
    />
  );
};

Button.defaultProps = {
  variant: 'primary',
};

export const IconButton = ({ icon: Svg, inverted, ...props }) => {
  const Tag = getButtonTag(props);
  return (
    <Tag
      {...props}
      sx={{
        ...(Tag === 'button' && resetButton),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'iconBox',
        height: 'iconBox',
        color: inverted ? 'background' : 'text',
        '&:hover': {
          color: inverted ? 'primary' : 'secondary',
        },
      }}
    >
      <Svg
        aria-hidden="true"
        sx={{
          width: 'icon',
          height: 'icon',
        }}
      />
    </Tag>
  );
};
