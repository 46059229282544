/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Button } from './Buttons';

const DownloadButton = ({ src, ...props }) => (
  <Button
    href={src}
    target="_blank"
    {...props}
    sx={{
      my: 10,
      display: 'inline-flex',
    }}
  >
    Download the article
  </Button>
);

export default DownloadButton;
