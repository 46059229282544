// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../../../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-fr-404-js": () => import("./../../../src/pages/fr/404.js" /* webpackChunkName: "component---src-pages-fr-404-js" */),
  "component---src-pages-fr-confidentialite-js": () => import("./../../../src/pages/fr/confidentialite.js" /* webpackChunkName: "component---src-pages-fr-confidentialite-js" */),
  "component---src-pages-fr-contact-merci-js": () => import("./../../../src/pages/fr/contact/merci.js" /* webpackChunkName: "component---src-pages-fr-contact-merci-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-blog-posts-page-js": () => import("./../../../src/templates/blog-posts-page.js" /* webpackChunkName: "component---src-templates-blog-posts-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-hospitals-page-js": () => import("./../../../src/templates/hospitals-page.js" /* webpackChunkName: "component---src-templates-hospitals-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jobs-page-js": () => import("./../../../src/templates/jobs-page.js" /* webpackChunkName: "component---src-templates-jobs-page-js" */),
  "component---src-templates-leadership-page-js": () => import("./../../../src/templates/leadership-page.js" /* webpackChunkName: "component---src-templates-leadership-page-js" */),
  "component---src-templates-markets-module-page-js": () => import("./../../../src/templates/markets-module-page.js" /* webpackChunkName: "component---src-templates-markets-module-page-js" */),
  "component---src-templates-markets-page-js": () => import("./../../../src/templates/markets-page.js" /* webpackChunkName: "component---src-templates-markets-page-js" */),
  "component---src-templates-module-page-js": () => import("./../../../src/templates/module-page.js" /* webpackChunkName: "component---src-templates-module-page-js" */),
  "component---src-templates-news-posts-page-js": () => import("./../../../src/templates/news-posts-page.js" /* webpackChunkName: "component---src-templates-news-posts-page-js" */),
  "component---src-templates-solutions-page-js": () => import("./../../../src/templates/solutions-page.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */),
  "component---src-templates-tech-posts-page-js": () => import("./../../../src/templates/tech-posts-page.js" /* webpackChunkName: "component---src-templates-tech-posts-page-js" */),
  "component---src-templates-why-page-js": () => import("./../../../src/templates/why-page.js" /* webpackChunkName: "component---src-templates-why-page-js" */)
}

