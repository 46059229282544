export const visuallyHidden = {
  border: 0,
  clip: 'rect(0, 0, 0, 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
};

export const linkStyles = ({ inverted } = {}) => ({
  color: inverted ? 'background' : 'text',
  textDecoration: 'none',
  '&:hover': {
    color: inverted ? 'primary' : 'secondary',
  },
});

export const resetButton = {
  background: 'transparent',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  border: 0,
  outline: 0,
  padding: 0,
  cursor: 'pointer',
};

export const resetLink = {
  textDecoration: 'none',
  color: 'inherit',
};

export const resetList = {
  listStyle: 'none',
  m: 0,
  p: 0,
};

export const iconButtonOffset = (t) =>
  `calc(-0.5 * (${t.sizes.iconBox} - ${t.sizes.icon}));`;
