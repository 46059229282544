import { random, mapValues } from 'lodash';
import { mapValuesDeep } from '../utils/map-deep';

export const palette = {
  royalBlueLighter: '#5282E0',
  royalBlue: '#175ADD',
  royalBlueDarker: '#073CA1',

  cyanLighter: '#A4EEEE',
  cyan: '#17DDDD',
  cyanDarker: '#0BBABA',

  greenLighter: '#A9DF8E',
  green: '#78c253',
  greenDarker: '#3BA108',

  cherryRedLighter: '#F07D99',
  cherryRed: '#EB2253',
  cherryRedDarker: '#C60736',

  purpleLighter: '#B27EEF',
  purple: '#8747D1',
  purpleDarker: '#560FA9',

  mustardLighter: '#FDD184',
  mustard: '#F4B951',
  mustardDarker: '#DD9008',

  navyBlue: '#042A6C',

  gray4: '#3D5374',
  gray3: '#98ABBB',
  gray2: '#D3DCE4',
  gray1: '#F2F6F8',

  white: '#fff',
  black: '#222222',

  m3: '#8A8A8A',
  m5: '#F2F2F2',
};

export const breakpoints = {
  /* A */
  /* B */ sm: '400px',
  /* C */ md: '750px',
  /* D */ lg: '1000px',
  /* E */ xl: '1200px',
  /* F */ xxl: '1600px',
};

export const breakpointsArr = Object.values(breakpoints);

export const mediaQueries = mapValues(
  breakpoints,
  (width) => `@media screen and (min-width: ${width})`
);

const space = [
  /* 0 */ 0,
  /* 1 */ '4px',
  /* 2 */ '8px',
  /* 3 */ '12px',
  /* 4 */ '16px',
  /* 5 */ '20px',
  /* 6 */ '24px',
  /* 7 */ '32px',
  /* 8 */ '40px',
  /* 9 */ '48px',
  /* 10 */ '56px',
  /* 11 */ '64px',
  /* 12 */ '72px',
  /* 13 */ '96px',
  /* 14 */ '128px',
];

const sizes = {
  headerHeight: '104px',
  headerHeightSlim: space[12],
  // headerHeightSmall: space[14],
  sectionOffset: space[8],
  sectionOffsetSmall: space[2],
  icon: '22px',
  iconBox: space[8],
};

const colors = {
  // required
  text: palette.black,
  background: palette.white,
  primary: palette.cyan,
  secondary: palette.purple,
  accent: palette.cherryRed,
  muted: palette.gray3,

  // custom
  ...palette,
  // success: palette.green,
  // warning: palette.mustard,
  // danger: palette.cherryRed,

  gradients: {
    cyan:
      'linear-gradient(-72.68deg, #17DDDD 19.98%, rgba(23, 221, 221, 0) 121.93%)',
    gray: 'linear-gradient(-72deg, #D3DCE4 19.44%, #F2F6F8 122.07%)',
    purple:
      'linear-gradient(180deg, #031E4E 1.78%, #560FA9 39.55%, #8747D1 68.01%)',
    home: 'linear-gradient(180deg, #042A6C 4.59%, #073CA2 40%, #175ADD 96.21%)',
    solutions:
      'linear-gradient(180deg, #031E4E 2.24%, #560FA9 45.82%, #8747D1 78.65%)',
    why:
      'linear-gradient(180deg, #F2F6F8 4.39%, #D3DCE4 21.6%, #98ABBB 96.21%)',
    company:
      'linear-gradient(180deg, #042A6C 2.19%, #042A6C 19.75%, #560FA9 44.9%, #8747D1 77.08%)',
    jobs:
      'linear-gradient(179.84deg, #031E4E 2.25%, #560FA9 46.05%, #8747D1 79.05%)',
    post:
      'linear-gradient(159.44deg, #FFFFFF 22.19%, #D3DCE4 103.19%, #94C4CE 103.2%, #D3DCE4 103.21%)',
    mobileNav:
      'linear-gradient(150.36deg, #FFFFFF 22.15%, #D3DCE4 103.03%, #94C4CE 103.04%, #D3DCE4 103.05%)',
  },

  mobileNav: {
    hoverBg: palette.gray2,
    hoverText: palette.purpleDarker,
    activeBg: palette.gray1,
    activeText: palette.purpleDarker,
  },

  button: {
    primary: {
      default: {
        text: palette.navyBlue,
        bg: palette.cyan,
        hoverBg: palette.cyanLighter,
      },
    },
    secondary: {
      default: {
        text: palette.white,
        bg: palette.purpleDarker,
        hoverBg: palette.purpleLighter,
      },
      inverted: {
        text: palette.white,
        bg: palette.purple,
        hoverBg: palette.purpleLighter,
      },
      outlined: {
        text: palette.purpleDarker,
        bg: palette.white,
        border: palette.purpleDarker,
        hoverText: palette.purpleLighter,
        hoverBorder: palette.prupleLighter,
      },
    },
  },
};

export const theme = {
  useCustomProperties: true,
  useBorderBox: false,

  // breakpoints: breakpointsArr,
  // mediaQueries,

  space,
  sizes,

  colors: {
    ...colors,
    modes: {
      gay: mapValuesDeep(colors, () => `hsl(${random(0, 359)}, 80%, 50%)`),
    },
  },

  sections: {
    navy: {
      bg: 'text',
      color: 'background',
    },
    cyan: {
      background: (t) => t.colors.gradients.cyan,
      color: 'text',
    },
    gray: {
      background: (t) => t.colors.gradients.gray,
      color: 'text',
    },
    purple: {
      background: (t) => t.colors.gradients.purple,
      color: 'background',
    },
    home: {
      background: (t) => t.colors.gradients.home,
      color: 'background',
    },
    solutions: {
      background: (t) => t.colors.gradients.solutions,
      color: 'background',
    },
    why: {
      background: (t) => t.colors.gradients.why,
      color: 'text',
    },
    company: {
      background: (t) => t.colors.gradients.company,
      color: 'background',
    },
    jobs: {
      background: (t) => t.colors.gradients.jobs,
      color: 'background',
    },
    post: {
      background: (t) => t.colors.gradients.post,
      color: 'text',
    },
    footer: {
      background: `linear-gradient(0deg, ${palette.gray2} 0%, #fff 70%)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 500px',
      backgroundPosition: 'bottom right',
      color: 'text',
    },
    mobileNav: {
      background: (t) => t.colors.gradients.mobileNav,
      color: 'text',
    },
  },

  lists: {
    default: {
      '> li:before': {
        bg: 'text',
      },
    },
    primary: {
      '> li:before': {
        bg: 'primary',
      },
    },
    secondary: {
      '> li:before': {
        bg: 'secondary',
      },
    },
    accent: {
      '> li:before': {
        bg: 'accent',
      },
    },
  },

  buttons: {
    primary: {
      bg: 'button.primary.default.bg',
      color: 'button.primary.default.text',
      '&:not([disabled]):hover': {
        bg: 'button.primary.default.hoverBg',
      },
    },
    'primary-inverted': {
      variant: 'buttons.primary',
    },
    secondary: {
      bg: 'button.secondary.default.bg',
      color: 'button.secondary.default.text',
      '&:not([disabled]):hover': {
        bg: 'button.secondary.default.hoverBg',
      },
    },
    'secondary-inverted': {
      bg: 'button.secondary.inverted.bg',
      color: 'button.secondary.inverted.text',
      '&:not([disabled]):hover': {
        bg: 'button.secondary.inverted.hoverBg',
      },
    },
    'secondary-outlined': {
      bg: 'button.secondary.outlined.bg',
      color: 'button.secondary.outlined.text',
      borderColor: 'button.secondary.outlined.border',
      borderStyle: 'solid',
      borderWidth: '2px',
      '&:not([disabled]):hover': {
        color: 'button.secondary.outlined.hoverText',
        borderColor: 'button.secondary.outlined.hoverBorder',
      },
    },
  },

  fonts: {
    body: "'Inter', sans-serif",
    heading: 'inherit',
    monospace: 'monospace',
  },

  // 300, 400, 700
  fontWeights: {
    body: 400,
    heading: 700,
    semibold: 600,
    bold: 700,
    light: 300,
  },

  lineHeights: {
    body: 1.825,
    heading: 1.375,
  },

  zIndices: {
    header: '100',
    mobileNav: '200',
    skipLink: '1000',
    devTools: '9999',
  },

  styles: {
    root: {
      fontSize: '16px',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
    },

    h1: {
      fontSize: ['26px', null, '38px'],
      fontWeight: ['bold', null, 'body'],
      textTransform: ['uppercase', null, 'none'],
      lineHeight: 'heading',
    },

    h2: {
      fontSize: ['20px', null, '34px', '34px'],
      fontWeight: ['bold', null, 'body'],
      textTransform: ['uppercase', null, 'none'],
      lineHeight: 'heading',
    },

    h3: {
      fontSize: '22px',
      lineHeight: 'heading',
    },

    p: {
      fontSize: '16px',
    },
  },
};
