/** @jsx jsx */
import { jsx } from 'theme-ui';

const Table = ({
  title,
  headers,
  rows,
  rowLabels,
  equalWidth = false,
  ...props
}) => {
  return (
    <div sx={{ my: 7 }}>
      <div sx={{ fontSize: '14px' }}>{title}</div>
      <table
        {...props}
        sx={{
          fontSize: '16px',
          borderCollapse: 'separate',
          borderSpacing: '32px 8px',
          mx: '-32px',
          mt: 4,
          td: {
            px: 4,
            py: 2,
            verticalAlign: 'top',
            borderRadius: '4px',
            bg: 'gray1',
          },
          th: {
            px: 4,
            py: 2,
            verticalAlign: 'top',
            borderRadius: '4px',
            bg: 'gray2',
          },
        }}
      >
        <thead>
          <tr>
            {rowLabels && <th>{rowLabels[0]}</th>}
            {headers.map((header, i) => (
              <th key={i}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <tr key={i}>
              {rowLabels && <th>{rowLabels[i + 1]}</th>}
              {row.map((cell, j) => {
                return (
                  <td
                    sx={
                      equalWidth
                        ? { width: `${(1 / headers.length) * 100}%` }
                        : {}
                    }
                    key={j}
                  >
                    {cell}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {};

export default Table;
